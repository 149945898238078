@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap");

body {
  background-color: #1c1c1c;
  background-image: url(../helpers/hearts.gif);
  background-size: 900px;
  background-repeat: repeat;
  color: lightgray;
}

.container {
  margin: 20% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  font-family: "Roboto Mono", monospace;
}

.title {
  color: #61a6bd;
}

.value {
  color: orange;
}

.brace {
  color: rgb(215, 40, 40);
}

a {
  color: orange;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
